
import { IGenerativeModelPromptUpdate } from "@/interfaces";
import { dispatchGetGenerativeConnectedDatasets } from "@/store/generative_model/actions";
import { readGenerativeModel, readConnectedDatasets } from "@/store/generative_model/getters";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";

import { api } from "@/api";

import { readHasAdminAccess, readToken, readUserProfile } from "@/store/main/getters";

import ConfusionMatrix from "@/components/ConfusionMatrix.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import ItemPreviewMultiLabel from "@/components/ItemPreviewMultiLabel.vue";
import ItemPreviewContext from "@/components/ItemPreviewContext.vue";
import LabelBar from "@/components/LabelBar.vue";
import ModelProgress from "@/components/ModelProgress.vue";
import Particle from "@/components/Particle.vue";
import RoundProgress from "@/components/RoundProgress.vue";
import ToolbarButton from "@/components/ToolbarButton.vue";
import TextEditor from "@/components/TextEditor.vue";
import { readDatasets } from "@/store/dataset/getters";

@Component({
  components: {
    ItemPreviewMultiLabel,
    ItemPreview,
    ItemPreviewContext,
    ModelProgress,
    LabelBar,
    Particle,
    ConfusionMatrix,
    ToolbarButton,
    RoundProgress,
    TextEditor,
  },
})
export default class PromptResponses extends Vue {
  public responses: string[] = [];
  public items: any = { responses: [] };
  public currentPage: number = 1;
  public pageCount: number = 1;

  public async mounted() {
    await dispatchGetGenerativeConnectedDatasets(this.$store, {
      workspaceId: parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId: parseInt(this.$router.currentRoute.params.id, 10),
    });

    await this.getConversations();
  }

  public async getConversations() {
    const modelId = parseInt(this.$router.currentRoute.params.id, 10);
    const response = await api.getConversations(
      this.token,
      parseInt(this.$router.currentRoute.params.workspaceid, 10),
      modelId,
      this.currentPage,
    );
    this.items = response.data;
    this.pageCount = response.data.total_pages;
  }

  public sendToConversation(conversationId) {
    // open a new window routing to the conversation view
    const datasetId = this.connectedDatasets[0].id;
    window.open(
      `/main/${this.$router.currentRoute.params.workspaceid}/datasets/${datasetId}/dashboard/browse?conversation_id=${conversationId}`,
      "_blank",
    );
  }

  get connectedDatasets() {
    return readConnectedDatasets(this.$store);
  }
  get model() {
    return readGenerativeModel(this.$store)(+this.$router.currentRoute.params.id);
  }

  get token() {
    return readToken(this.$store);
  }
}
